import React, { useState, useEffect}  from 'react';
import { PostRequest } from 'components/common/index.js';
import { LogInRequired, LoggedInCanDownload, LoggedInPurchaseRequired, NeedsToConfirmEmail } from 'components/show_drawing_download_section/download_buttons/index.js';

const DownloadButtonsSection = ( { id, drawing, currentDrawingOptions, currentUserDrawingOptions, advancedOptionsVisible, dimensionTextschecked, redrawInProgress } ) => {
  const [mode, setMode] = useState('none');
  const [statusFetched, setStatusFetched] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [canDownload, setCanDownload] = useState(false);

  const [promptForCreateAccountEmail, setPromptForCreateAccountEmail] = useState('');

  const delayGetStatus = 500;

  const getStatus = () => {
    const timeOutId = setTimeout(getStatusAction, delayGetStatus);
    return () => clearTimeout(timeOutId);
  }

  const getStatusAction = () => {
    PostRequest({
      url: '/download_button/status/' + id,
      data: currentDrawingOptions,
      302: () => {location.reload(true);},
      callback: ( data ) => {
        setLoggedIn( _prev => data.logged_in )
        setConfirmed( _prev => data.confirmed )
        setCanDownload( _prev => data.can_download )
        setStatusFetched( () => true );
        if(data.omniauth_sign_in_failed_account_does_not_exists) {
          setPromptForCreateAccountEmail(() => data.omniauth_email)
        }
      }
    })
  }

  useEffect( () => {
    let listen = 'show_drawing.downloads_buttons_refresh_required';
    document.addEventListener(listen, getStatus);
    return () => { document.removeEventListener(listen, getStatus);}
  }, [])

  useEffect(getStatus, [drawing, currentDrawingOptions])

  if(!statusFetched) {
    return;
  }

  return (
    <div>
      {!loggedIn && (
        <div className="float-right">
          <LogInRequired mode={mode} setMode={setMode} promptForCreateAccountEmail={promptForCreateAccountEmail} callback={refreshDownloadButtons} />
        </div>
      )}

      {loggedIn && canDownload && (
        <div className="float-right">
          <LoggedInCanDownload
            id={id}
            drawing={drawing}
            currentDrawingOptions={currentDrawingOptions}
            currentUserDrawingOptions={currentUserDrawingOptions}
            advancedOptionsVisible={advancedOptionsVisible}
            dimensionTextschecked={dimensionTextschecked}
            redrawInProgress={redrawInProgress}
          />
        </div>
      )}

      {loggedIn && !canDownload && confirmed && (
        <div className="float-right">
          <LoggedInPurchaseRequired id={id} drawing={drawing}/>
        </div>
      )}

      {loggedIn && !canDownload && !confirmed && (
        <div className="float-right">
          <NeedsToConfirmEmail onSuccess={refreshDownloadButtons} />
        </div>
      )}

    </div>
  )
}

export default DownloadButtonsSection;
