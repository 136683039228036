import React, { useState, useEffect, useRef, createRef } from 'react';
import {AskConfirm, ConfirmPopup} from 'components/ConfirmPopup.jsx';
import { GetRequest, PostRequest, NumberInput } from 'components/common';
import { ContentPopup } from 'components/ContentPopup.jsx';

const QuoteBuilderList = ({config, setComponentShowingState, setItemIdBeingEdited, hideDeleteFromList}) => {
  const default_list_path = '/quote-builder/user_unit_prices/list/settings_list/:key/:lng';
  const default_system_list_path = '/quote-builder/system_user_unit_prices/list/settings_list/:key/:lng';
  const default_delete_path = '/quote-builder/user_unit_prices/delete';
  const default_duplicate_path = '/quote-builder/user_unit_prices/duplicate';
  const default_update_attribute_path = '/quote-builder/user_unit_prices/update_attribute/:id';

  const STATE_EDIT  = 'edit';
  const STATE_COPY_FROM_SYSTEM  = 'copy_from_system';
  const STATE_NEW  = 'new';
  const LISTING_STATE_ALL  = 'all';

  const [listingState, setListingState] = useState(LISTING_STATE_ALL);

  const confirmPopupRef = useRef({});
  const [items, setItems] = useState([]);
  const [systemItems, setSystemItems] = useState([]);
  const [fetchInProgress, setFetchInProgress] = useState(false);
  const [inlineEditActive, setInlineEditActive] = useState(false);
  const [inlineEditableIndex, setInlineEditableIndex] = useState(0);
  const [inlineEditableValue, setInlineEditableValue] = useState('');


  useEffect(() => {
    if(window.QuoteBuilderListScrollTop && listingState == STATE_EDIT) {
      $("html, body").animate({ scrollTop: 100 }, "slow");
    }
  }, [listingState])

  const fetchItems = () => {
    setFetchInProgress(true);
    setListingState(LISTING_STATE_ALL);

    GetRequest({
      url: (config.list.path || default_list_path).replace(':key', config.key).replace(':lng', window.locale),
      callback: (data) => {
        setItems(data);
        fetchSystemItems()
        setFetchInProgress(false);
      }
    })
  }

  const fetchSystemItems = () => {
    setFetchInProgress(true);
    GetRequest({
      url: default_system_list_path.replace(':key', config.key).replace(':lng', window.locale),
      callback: (data) => {
        setSystemItems(data);
        setFetchInProgress(false);
      }
    })
  }

  useEffect(fetchItems, [])

  const editAction = (event, id) => {
    event.preventDefault();
    setComponentShowingState(STATE_EDIT);
    setItemIdBeingEdited(id);
  }

  const dublicateAction = (event, id) => {
    event.preventDefault();

    PostRequest({
      url: (config.list.duplicate_path || default_duplicate_path),
      data: { id: id },
      callback: (data) => {
        fetchItems();
        setFetchInProgress(false);
        document.dispatchEvent(new CustomEvent('quote_builder.user_unit_price_saved', {}) );
      },
      403: (data) => {
        alert(data.message);
      }
    })
  }

  const showPerformDeletePermissionDeniedRef = useRef();
  const showPerformDeletePermissionDenied = (message) => {
    let callback = () => {
      showPerformDeletePermissionDeniedRef.current.style.display = "none";
    };

    let response = <ContentPopup myRef={showPerformDeletePermissionDeniedRef} autoOpen={false} content={message} cancelButton={false} beforeClose={callback} />;

    return response;
  }

  const performDelete = (id) => {
    PostRequest({
      url: (config.list.delete_path || default_delete_path),
      data: { id: id },
      callback: (data) => {
        fetchItems();
        setFetchInProgress(false);
        document.dispatchEvent(new CustomEvent('quote_builder.user_unit_price_deleted', {}) );
      },
      403: (data) => {
        alert(data.message);
        // showPerformDeletePermissionDenied(data.message);
      }
    })
  }

  const deleteConfirm = (event, id) => {
    event.preventDefault();
    AskConfirm(
      confirmPopupRef,
      t('quote_builder.delete_confirm'),
       performDelete,
      id
    );
  }

  const [createBtnEnabled, setCreateBtnEnabled] = useState('disabled');
  const [createFromSystemEnabled, setCreateFromSystemEnabled] = useState(false);

  useEffect(() => {

    GetRequest({
      url: default_system_list_path.replace(':key', config.key).replace(':lng', window.locale),
      callback: (data) => {
        setCreateFromSystemEnabled(() => { return data.length > 0});
        setCreateBtnEnabled(() => { return '' });
      }
    })

  }, []);

  const newAction = (event) => {
    event.preventDefault();
    setItemIdBeingEdited(null);
    setComponentShowingState(STATE_NEW);
  }

  const handleInlineEditableClick = (e, index) => {
    e.preventDefault();
    setInlineEditActive((prev) => { return !prev } );
    setInlineEditableIndex((prev) => { return index });
  }

  const handleInlineEditableSave = (e) => {
    e.preventDefault();

    PostRequest({
      url: (config.list.update_attribute_path || default_update_attribute_path).replace(':id', inlineEditableIndex),
      data: {
        key: config.list.inline_editable_attribute,
        value: inlineEditableValue
      },
      callback: (data) => {
        setInlineEditActive((prev) => { return false });
        setInlineEditableIndex((prev) => {return -1 } );
        fetchItems();
        setFetchInProgress(false);
      }
    })
  }

  const handleInlineEditChange = (e) => {
    setInlineEditableValue(e.target.value);
  }


  useEffect(() => {
    if(items.length == 0) { return }

    setInlineEditableValue( (prev) => {
      for(let i = 0; i< items.length; i++){
        if(items[i].id == inlineEditableIndex) {
          return items[i][config.list.inline_editable_attribute];
        }
      }
    } );
  }, [inlineEditActive])

  const defaultListTitle = () => {
    return t(`quote_builder.${config.key}_plural`)
  }

  const defaultNewTitle = () => {
    return t(`quote_builder.new_user_unit_price`).replace("%s", t(`quote_builder.${config.key}`).toLowerCase() )
  }

  const isMarkSystemEnabled = () => {
    return listingState == LISTING_STATE_ALL && config.mark_system != undefined && config.mark_system.enabled && window.mark_system_enabled;
  }

  const selectSystemAction = (e, id) => {
    e.preventDefault();
    setComponentShowingState(STATE_COPY_FROM_SYSTEM);
    setItemIdBeingEdited(id);
  }

  const listedAttributes = () => {
    return config.list.attributes;
  }

  const systemItemRow = (item) => {
    return (<tr>
      <td>
        <div className="float-left">{item.description_for_system} </div>
        <div className="float-right">
          <a href="#" data-id={item.id} className="btn btn-default simple-btn" onClick={(e) => { selectSystemAction(e, item.id) } }>
            <i className="zmdi zmdi-open-in-browser"></i> {t('quote_builder.select_and_customise')}
          </a>
        </div>
      </td>
    </tr>)
  }

  const systemItemsList = () => {
    return (
      <table className="table table-striped table-bordered">
        <tbody>
          <tr>
            <th colSpan={listedAttributes().length + 3}>
              <div className="float-left">
                {config.list.title != undefined ? t(config.list.title) : defaultListTitle() } - {t(`quote_builder.listing_state_system_title`)}
              </div>
            </th>
          </tr>
          {systemItems.map(systemItemRow)}
        </tbody>
      </table>
    )
  }

  const [percentageValue, setPercentageValue] = useState(0);
  const handlePercentageValueChance = (e) => {
    let targetValue = e.target.value;
    if(targetValue < 0) { targetValue = 0;}
    setPercentageValue(targetValue);
  }

  const handleDelayedInputValuePercentage = (value) => {
    if(value == 0) { fetchItems(); return };
    if(items.length == 0) {  return };

    PostRequest({
      url: '/quote-builder/user_unit_prices/preview_percentage_change',
      data: {
        ids: checkedItemIds,
        selected_item_action: selectedItemAction,
        percentage_value: percentageValue
      },
      callback: (data) => {
        let originalItems = items;
        let attributes = listedAttributes();
        for(let j=0; j< data.length; j++) {
          for(let i=0; i< items.length; i++) {
            if(originalItems[i].id == data[j].id) {
              for(let k=0; k< attributes.length; k++) {
                let attribute = attributes[k];
                if(originalItems[i][attribute] != data[j][attribute]) {
                  originalItems[i]['alternative'] = {}
                  originalItems[i]['alternative'][attribute] = getDiff(originalItems[i][attribute], data[j][attribute]);
                }
              }

            }
          }
        }

        setItems( (_prev) => { return [ ...originalItems ] } );

      },
      403: (data) => {
        //alert(data.message);
      }
    })
  }

  const getDiff = (str1, str2) => {
    let retVal = [];
    let str1A = str1.split(" ");
    let str2A = str2.split(" ");

    for(let i=0; i < str1A.length ; i++) {
      if(str1A[i] == str2A[i]) {
        retVal.push( str1A[i] );
      } else {
        retVal.push(`<span class="percentageOld"> ${str1A[i]} </span> <span class="percentageNew"> ${str2A[i]} </span>`);
      }
    }

    return retVal.join(" ");
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => handleDelayedInputValuePercentage(percentageValue), 500);
    return () => clearTimeout(timeOutId);
  }, [percentageValue]);

  const [selectedItemAction, setSelectedItemAction] = useState('increase');
  const handleSelectedItemActionChange = (e) => {
    setSelectedItemAction(e.target.value);
  }

  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const handleItemChecked = (event) => {
    let id = event.target.id;
    if(event.target.checked) {
      setCheckedItemIds( (prev) => { return [...prev, id]} )
    } else {
      setCheckedItemIds( (prev) => { return prev.filter(item_id => item_id !== id)} )
    }
  }

  const confirmSelectedItemsAction = () => {
   if(confirm(t('quote_builder.user_unit_prices_update_confirm').replace('%s', checkedItemIds.length))) {
      PostRequest({
        url: '/quote-builder/user_unit_prices/action_percentage_change',
        data: {
          ids: checkedItemIds,
          selected_item_action: selectedItemAction,
          percentage_value: percentageValue
        },
        callback: (data) => {
          unSelectAll();
          fetchItems();

        },
        403: (data) => {
          //alert(data.message);
        }
      })
   }
  }

  useEffect( () => {
    handleDelayedInputValuePercentage(percentageValue);
  }, [selectedItemAction] )

  const confirmSelectedItemsActionDisabled = () => {
    return !(percentageValue > 0);
  }

  const unSelectAll = () => {
    $('.multi-select-chc').each(function() {
      if($(this).prop('checked')) {
        $(this).prop('checked', false);
        $(this).trigger('change');
      }
    });

    setCheckedItemIds(() => { return [] });
    setPercentageValue( () => {return 0 } );
  }

  const selectAll = () => {
    $('.multi-select-chc').each(function() {
      if(!$(this).prop('disabled')) {
        if(!$(this).prop('checked')) {
          $(this).prop('checked', true);
          $(this).trigger('change');
        }
      }
    });

    setCheckedItemIds(() => { return items.filter((item) => {return !item.is_system}).map( (item) => { return item.id } ) });
  }

  const cancelSelectedItemsAction = () => {
    unSelectAll();
    fetchItems();
  }

  const manageCheckedItemsForm = () => {
    if(checkedItemIds.length == 0) { return }
    return (
      <table className="table manageCheckedItemsTable" >
        <tbody>
         <tr>
          <td>
            <select className="form-control" name={selectedItemAction} onChange={handleSelectedItemActionChange}>
              <option value='increase'> {t('quote_builder.increase_selected_prices').replace("%s", checkedItemIds.length) }  </option>
              <option value='descrease'> {t('quote_builder.descrease_selected_prices').replace("%s", checkedItemIds.length) } </option>
            </select>
          </td>
          <td>
           <div className="input-group number_input form-short">
            {window.locale == 'tr' && <span className="input-group-addon"> <span> {t('quote_builder.percent_in_form')} </span> </span>}
            <input size="6" className="form-control" type="number" value={percentageValue} onChange={handlePercentageValueChance} step="0.1" />
            {window.locale == 'en' && <span className="input-group-addon"> <span> {t('quote_builder.percent_in_form')} </span> </span>}
            </div>

          </td>

          <td>
            <button className="btn simple-btn" disabled={confirmSelectedItemsActionDisabled()} onClick={confirmSelectedItemsAction}> <i className="fa fa-check" aria-hidden="true"></i>  {t('quote_builder.confirm')}  </button>
          </td>
          <td>
            <button className="btn" onClick={cancelSelectedItemsAction}> {t('quote_builder.cancel')}  </button>
          </td>
          </tr>
        </tbody>
      </table>
    )
  }

  const isMultiSelectEnabled = () => {
    return config.disable_multi_select_actions == undefined || config.disable_multi_select_actions == false;
  }

  const numberOfManageButtons = () => {
    return isMultiSelectEnabled() ? 4 : 3;
  }

  const handleAllChecked = (e) => {
    // e.preventDefault();

    if(e.target.checked) {
      selectAll();
    } else {
      unSelectAll();
    }
    setAllChecked(!allChecked);
  }

  const [allChecked, setAllChecked] = useState(false)
  return (
    <>
      <ConfirmPopup myRef={confirmPopupRef} />
      <div className="myListRef">

        <table className="table table-striped table-bordered">
          <tbody>
          <tr>
          <th colSpan={listedAttributes().length + numberOfManageButtons() }>
            <div className="float-left">
              <input type="checkbox"  checked={allChecked} className="all-select-chc" onChange={handleAllChecked} disabled={items.length == 0 ? 'disabled' : ''}/>  &nbsp; &nbsp;
              {t('quote_builder.my_unit_prices')}
              &nbsp;
              -
              &nbsp;
              {listingState == LISTING_STATE_ALL && config.list.title != undefined && t(config.list.title)}
              {listingState == LISTING_STATE_ALL && config.list.title == undefined && defaultListTitle()}
              &nbsp;
              {listingState == LISTING_STATE_ALL && (<>({items.length})</>) }
            </div>
          </th>
          </tr>

          {items.map((item) => {
            return (
              <tr className={`${isMarkSystemEnabled() && item.is_system ? 'user-unit-price-system' : ''}`} >
               { isMultiSelectEnabled() && (
                  <td> <input type="checkbox" className="multi-select-chc" key={item.id} id={item.id} onChange={handleItemChecked} disabled={item.is_system ? 'disabled' : ''}/> </td>
                )}

                {listingState == LISTING_STATE_ALL && listedAttributes().map((attribute) => {
                  return (
                    <td>
                      {inlineEditActive && item.id == inlineEditableIndex && (<>

                        {config.list.inline_editable_attribute == attribute && (<>
                          <input type="text" size="25" value={inlineEditableValue} onChange={handleInlineEditChange} placeholder={t(config.list.inline_editable_attribute_placeholder)}/>
                          <button onClick={handleInlineEditableSave} className="btn btn-default btn-sm no-border"> <i className="fa fa-floppy-o" aria-hidden="true" title="Save"></i> Save </button>
                        </>)}

                        {config.list.inline_editable_attribute != attribute && item[attribute] }

                      </>)}

                      {!(inlineEditActive && item.id == inlineEditableIndex) && (<>
                        <span dangerouslySetInnerHTML={{__html: (item['alternative'] != undefined && item['alternative'][attribute] != undefined) ? item['alternative'][attribute] : item[attribute] }}></span>

                        {config.list.inline_editable_attribute == attribute && (
                          <button onClick={ (e) => { handleInlineEditableClick(e, item.id) }} title={t(config.list.inline_editable_attribute_placeholder)} className="btn btn-default btn-sm no-border"> ... <i className="zmdi zmdi-edit" aria-hidden="true" title={t(config.list.inline_editable_attribute_placeholder)} ></i></button>
                        )}
                      </>)}
                    </td>
                  )
                })}

                {listingState == LISTING_STATE_ALL && config.list.edit_enabled &&    <td> <a href="#" data-id={item.id} onClick={(e) => { editAction(e, item.id) } }> <i className="fa fa-pencil-square-o" aria-hidden="true"></i> {t('quote_builder.edit')} </a> </td> }
                {listingState == LISTING_STATE_ALL && config.list.create_enabled && <td> <a href="#" data-id={item.id} onClick={(e) => { dublicateAction(e, item.id) } }> <i className="fa fa-files-o" aria-hidden="true"></i> {t('quote_builder.duplicate')} </a> </td> }
                {listingState == LISTING_STATE_ALL && config.list.delete_enabled && !hideDeleteFromList &&  <td> <a href="#" data-id={item.id} onClick={(e) => { deleteConfirm(e, item.id) } }> <i className="fa fa-trash-o" aria-hidden="true"></i> {t('quote_builder.delete')} </a> {isMarkSystemEnabled() && item.is_system && (<div className="float-right"> <i className="zmdi zmdi-library"></i> [kütüphane]</div>) } </td> }
              </tr>
            )
          })}
          <tr>

          {config.list.create_enabled &&
            <td colSpan={listedAttributes().length + numberOfManageButtons() }>
              <div className="float-left">
                {(manageCheckedItemsForm())}

                <button onClick={newAction} disabled={createBtnEnabled} className="btn simple-btn">
                  {createBtnEnabled == 'disabled' && (<> {t('quote_builder.please_wait') }</>) }
                  {createBtnEnabled != 'disabled' && (<>
                    <i className="fa fa-plus-square-o" aria-hidden="true"></i>
                      &nbsp;
                      {config.list.new_title!= undefined && t(config.list.new_title)}
                      {config.list.new_title== undefined && defaultNewTitle()}
                    </>)}
                  </button>
              </div>
            </td>}

          </tr>
          </tbody>
        </table>

         {systemItems.length > 0 && (systemItemsList())}
      </div>
    </>
  )
}

export default QuoteBuilderList;
