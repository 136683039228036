import React, { useState, useEffect } from 'react';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';
import SettingsMenu from 'components/quote_builder/SettingsMenu.jsx';

const Settings = ( {config, user_unit_price_key} ) => {
  const [activeItem, setActiveItem] = useState(isMobile() ? undefined : (user_unit_price_key || getCookie('quote_builder_default_settings_item') || "carton_material") );

  useEffect(() => {
    $("html, body").animate({ scrollTop: 100 }, "slow");
  }, [activeItem])

  return (
    <div className="row" style={{"marginLeft": "-30px"}}>
      <div className="col-lg-3 SettingsMenu">
        <SettingsMenu activeItem={activeItem} setActiveItem={setActiveItem} />
      </div>
      <div className="col-lg-9 SettingsMenuMainCont">
        {activeItem == "carton_material" && <QuoteBuilderForm config={config.user_unit_prices.carton_material} /> }
        {activeItem == "hard_cardboard_material" && <QuoteBuilderForm config={config.user_unit_prices.hard_cardboard_material} /> }
        {activeItem == "corrugated_cardboard_material" && <QuoteBuilderForm config={config.user_unit_prices.corrugated_cardboard_material} /> }
        {activeItem == "pvc_material" && <QuoteBuilderForm config={config.user_unit_prices.pvc_material} /> }
        {activeItem == "coating_material" && <QuoteBuilderForm config={config.user_unit_prices.coating_material} /> }

        {activeItem == "hot_stamping_material" && <QuoteBuilderForm config={config.user_unit_prices.hot_stamping_material} /> }
        {activeItem == "hot_stamping_printing_block" && <QuoteBuilderForm config={config.user_unit_prices.hot_stamping_printing_block} /> }
        {activeItem == "hot_stamping_machine" && <QuoteBuilderForm config={config.user_unit_prices.hot_stamping_machine} /> }

        {activeItem == "cellophane_material" && <QuoteBuilderForm config={config.user_unit_prices.cellophane_material} /> }
        {activeItem == "cellophane_machine" && <QuoteBuilderForm config={config.user_unit_prices.cellophane_machine} /> }

        {activeItem == "printing_machine" && <QuoteBuilderForm config={config.user_unit_prices.printing_machine} /> }

        {activeItem == "guillotine_machine" && <QuoteBuilderForm config={config.user_unit_prices.guillotine_machine} /> }
        {activeItem == "die_cutting_machine" && <QuoteBuilderForm config={config.user_unit_prices.die_cutting_machine} /> }
        {activeItem == "metal_strip" && <QuoteBuilderForm config={config.user_unit_prices.metal_strip} /> }
        {activeItem == "cutting_board" && <QuoteBuilderForm config={config.user_unit_prices.cutting_board} /> }

        {activeItem == "glueing_machine" && <QuoteBuilderForm config={config.user_unit_prices.glueing_machine} /> }
        {activeItem == "glueing_manual_labor" && <QuoteBuilderForm config={config.user_unit_prices.glueing_manual_labor} /> }
        {activeItem == "glueing_material" && <QuoteBuilderForm config={config.user_unit_prices.glueing_material} /> }

        {activeItem == "lamination_machine" && <QuoteBuilderForm config={config.user_unit_prices.lamination_machine} /> }
        {activeItem == "lamination_manual_labor" && <QuoteBuilderForm config={config.user_unit_prices.lamination_manual_labor} /> }

        {activeItem == "magnet_material" && <QuoteBuilderForm config={config.user_unit_prices.magnet_material} /> }

        {activeItem == "coating_machine" && <QuoteBuilderForm config={config.user_unit_prices.coating_machine} /> }
        {activeItem == "coating_manual_labor" && <QuoteBuilderForm config={config.user_unit_prices.coating_manual_labor} /> }

      </div>
    </div>
  )
}

export default Settings;
