import React, { useEffect, useState, useRef } from 'react';

import { PostRequest } from 'components/common/index.js';

import SignUp from 'components/session/SignUp.jsx';
import SignIn from 'components/session/SignIn.jsx';

import { ContentPopup } from 'components/ContentPopup.jsx';

const LogInRequired = ({ mode, setMode, promptForCreateAccountEmail, callback, autoOpen }) => {
  const handleBackClick = (e) => {
    e.preventDefault();
    setShowSignUp(() => false)
    setShowSignIn(() => true)
  }

  const backButton = () => {
    return (
      <span style={{'display':'inline', 'float':'right'}}>
        &nbsp;
        &nbsp;
        &nbsp;
        <button className="btn simple-btn"  onClick={handleBackClick}>
          <i className="zmdi zmdi-replay"></i> {t('nav.back')}
        </button>
      </span>
    );
  }

  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);

  const [backButtonVisible, setBackButtonVisible] = useState(true);

  const handleShowSignUp = (e) => {
    e.preventDefault();
    setShowSignUp(() => true)
    setShowSignIn(() => false)
  }

  const handleShowSignIn = (e) => {
    e.preventDefault()
    setShowSignIn(() => true)
    setShowSignUp(() => false)
  }

  useEffect(() => {
    if(promptForCreateAccountEmail != '' && promptForCreateAccountEmail != undefined) {
      setShowSignIn(() => true)
      setShowSignUp(() => false)
      setShowSignInPopUp( () => true )
    }
  }, [promptForCreateAccountEmail])

  const [showSignInPopUp, setShowSignInPopUp] = useState(autoOpen != undefined ? autoOpen : false)

  useEffect( () => {
    if(showSignInPopUp) {
      trackEvent('js_please_log_in_button', {
        button: 'pricing_page',
      });
    }

  }, [ showSignInPopUp ]);

  return(
    <div className="wrapper">
      <div className="testimonials-block preview-promo-block">
       {showSignUp && backButtonVisible && (
        <>
          {backButton()}
        </>
      )}
        {showSignUp && <SignUp setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} setBackButtonVisible={setBackButtonVisible} onSuccess={callback} />}
        {showSignIn && <SignIn promptForCreateAccountEmail={promptForCreateAccountEmail} setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} onSuccess={callback}/>}
     </div>
   </div>
  )
}

export default LogInRequired;
