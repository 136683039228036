import React,{ useState, useEffect } from 'react';
import OptionRowFloat from 'components/layout/options/OptionRowFloat.jsx'
import { HandleActivationFormula , HandleOptionsUserChanged} from 'components/layout/options/drawing_options/index.js';

const Length = ({ item, option, unit, value, onChange, enabled, doNotLoadFromLocalStorage} ) => {
  const updateOptionsUserChanged = (_e) => { HandleOptionsUserChanged().add(option.name) };
  const [active, setActive] = useState(true);
  const [weRecommend, setWeRecommend] = useState('');
  const [autoCalculated, setAutoCalculated] = useState(false);
  const optionsUserChanged = () => { return window.dielineOptions["options_user_changed"].split(','); }

  useEffect(() => {
    if(weRecommend != undefined && weRecommend != '' && !optionsUserChanged().includes(option.name) ) {
      onChange(parseFloat(weRecommend).toFixed(unit == 'in' ? 4 : 2));
      setAutoCalculated( (_prev) => { return true } );
    } else {
      setAutoCalculated( (_prev) => { return false } );
    }
  }, [weRecommend]);

  const attachEvents = () => {
    if (option.has_activation_formula || option.has_value_formula) {
      return HandleActivationFormula({
        option: option,
        item: item,
        setActive: setActive,
        setWeRecommend: setWeRecommend,
      });
    }
  }

  useEffect(attachEvents, []);

  return (<div>
    {active && enabled && <OptionRowFloat
      wide="true"
      item={item}
      key={option.id}
      description={option.description}
      name={option.name}
      unit={unit}
      value={value}
      onChange={onChange}
      onKeyUp={updateOptionsUserChanged}
      disabled={option.readonly_option}
      doNotLoadFromLocalStorage={doNotLoadFromLocalStorage}
      abc_line={option.name}
    />}<div>
      {active && enabled && autoCalculated && <div className="drawing-option-autocalculated-cont"> <small> {t('drawing.this_has_been_automaticly_calculated') }  </small> </div>}
      {active && enabled && weRecommend != '' && weRecommend != undefined && <div className="drawing-option-recommend-cont"> <small> {t('drawing.choose_option_recommmended') }: {weRecommend} </small> </div>}
      </div>
  </div>)
}

export default Length;
