import React, { useState, useEffect, createRef }  from 'react';
import MyEventListener from 'components/common/MyEventListener.jsx'

const DebugPanel = ( { debugEntry, setDebugEntries } ) => {
  const showDebugEntry = (entry) => {
     var str = ""
     for (const [key, value] of Object.entries(entry)) {
      if(key != 'event') {
        str = `${str} ${key}=${JSON.stringify(value)}`
      }
    }
    return <div> <strong> <small>{entry.event}  </small> </strong> {str} </div>
  }

  const debugContent = () => {
    return <div  className="card" style={{position: 'absolute', left: "860px", top: "0px", zIndex: 100, width: "700px"}}>
    <button onClick={(e) => { e.preventDefault(); setDebugEntries( () => [] ) }} >sil </button>
    <div style={{ 'backgroundColor': '#f5f5f5' }}>
       <small>{debugEntry.map(showDebugEntry) } </small>
    </div>
    <hr />
    <button onClick={(e) => { e.preventDefault(); setDebugEntries( () => [] ) }} >sil </button>
    </div>
  }

  return debugContent();
}

export default DebugPanel;
