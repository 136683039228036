import React, { useState, useEffect, useContext } from 'react';
import LayoutContext from 'components/contexts/LayoutContext.jsx';
import { Angle, Bool, Integer, Length } from 'components/layout/options/drawing_options/index.js'

const ChooseAdvancedOptions = ( {item, member_klass, member_id, recoveryApplyMethods} ) => {
  const [enabled, setEnabled] = useState(false);
  const myLayoutContext = useContext(LayoutContext);
  const getUUID = () => { return item.uuid; }

  const defaultOptions = () => {
    if(myLayoutContext.unit == 'in') {
      return {...myLayoutContext.optionsUserEntered }
    } else {
      return {...myLayoutContext.formulaOptions }
    }
  };

  const [advancedOptions, setAdvancedOptions] = useState([]);
  const [formulaOptions, setFormulaOptions] = useState( {} );

  const fetchLayoutTemplateOptions = () => {
    fetch(`/layouts-template-advanced-options/${member_klass}/${member_id}`)
      .then(response => response.json())
      .then( (data) => {
        setFormulaOptions( (prev) => {
          prev[ getUUID() ] = defaultOptions()
          return { ... prev }
        });

        setAdvancedOptions( (_prev) => { return data } );

        data.map( (optionData) => {
          let name = optionData.name;
          let defaultVal = defaultOptions()[name];
          let layoutOptions = objectifyForm($(`#form_${item.uuid}`));
          let existingValue = layoutOptions[name];
          let value = existingValue == undefined ? defaultVal : existingValue;

          setFormulaOptions( (prev) => {
            prev[ getUUID() ][name] = value;
            return { ... prev }
          });
        } )
      })
  }

  const getOnChangeFor = (option) => {
    return (val) => setFormulaOptions((previous) => {
      if(!enabled) { setEnabled( () => { return true } );}
      previous[getUUID()][option.name] = val;
      return { ...previous }
    })
  }

  const getValueByName = (name) => {
    if(formulaOptions[ getUUID() ] == undefined) {
      let default_options = defaultOptions();
      setFormulaOptions( (prev) => {
        prev[ getUUID() ] = default_options;
        return { ... prev }
      })

      return null;
    }

    return formulaOptions[ getUUID() ][name];
  }

  const setRecoveryApplyMethodFor = (option) => {
    if(recoveryApplyMethods == undefined) {
      return () => {};
    }
    recoveryApplyMethods.current[option.name] = getOnChangeFor(option)
  }

  const optionFactory = (option) => {
    let formField;
    switch(option.input_type) {
      case 'integer':
        formField = <Integer
          key={option.id}
          item={item}
          option={option}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={enabled}
          doNotLoadFromLocalStorage={true}
        />
        break;

      case 'length':
        formField = <Length
          key={option.id}
          item={item}
          option={option}
          unit={myLayoutContext.unit}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={enabled}
          doNotLoadFromLocalStorage={true}
        />
        break;

      case 'boolean':
        formField = <Bool
          key={option.id}
          item={item}
          option={option}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={enabled}
          doNotLoadFromLocalStorage={true}
        />
        break;

      case 'angle':
        formField = <Angle
          key={option.id}
          item={item}
          option={option}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={enabled}
          doNotLoadFromLocalStorage={true}
        />
        break;
      }

      return formField;
  }

  useEffect( () => {
    advancedOptions.map((option) => {
      setRecoveryApplyMethodFor(option);
    })
  }, [advancedOptions]);

  useEffect(fetchLayoutTemplateOptions, [member_klass, member_id]);

  useEffect(() => {
    if(enabled) {
      document.dispatchEvent(new CustomEvent('REDRAW_REQUESTED', { "detail": item.uuid }));
      trackEvent('js_nesting_advanced_options', {
        'item_uuid': item.uuid
     });
    }
  }, [enabled]);

  useEffect( () => {
    setEnabled( () => { return false } );
    return () => { setEnabled( () => {return false} )}
  }, [item]);

  return(<>
    {!enabled && advancedOptions.length > 0 && (
      <div className="LayoutRedrawFormSection">
         <a href="#" onClick={(e) => {e.preventDefault(); setEnabled(() => { return true })} }>
          <i className="fa fa-gears" ></i> {t('drawing.show_advanced_options') }
        </a>
      </div>
    )}

    {enabled && advancedOptions.length > 0 &&
      <div className="LayoutRedrawFormSection">
        <a href="#" onClick={(e) => {e.preventDefault(); setEnabled(() => { return false })} }>
          <i className="fa fa-gears" ></i> {t('drawing.hide_advanced_options') }
        </a>
        {advancedOptions.map((option) => {
          return optionFactory(option)
        })}
      </div>
    }
  </>
  );

}

export default ChooseAdvancedOptions;
