import React, { useState, useEffect, useRef }  from 'react';
import { ContentPopup } from 'components/ContentPopup.jsx';
import { GetRequest } from 'components/common/index.js';
import { PricingTables, PaymentForm, ChangeCurrency } from 'components/payment/index.js';
import PaymentSuccess from 'components/pricing/PaymentSuccess.jsx';
import LogInRequired from 'components/pricing/LogInRequired.jsx';

const BuySubscriptionButton = ({ type, subscription_type, cta }) => {
  const handleClick = (e) => {
    if(e != undefined) {  e.preventDefault() }
    setShowPopUp( () => true );
    fetchSubscription();
  }

  const subscriptionType = () => {
    if(type == 'credits') { return $('input[name="subscription_type"]:checked').val(); }
    return type;
  }

  const loadingIcon = () => { return (<><img src={window.loading_icon} /> <br /> <br /></>) }
  const fetchSubscription = () => {
    GetRequest({
      url: '/charge/subscriptions',
      callback: (data) => {
        setCurrencyName(data.currency_name)
        setSubscription( (_prev) => data[subscriptionType()] )

        if(data.logged_in) {
          setMode(() => 'payment_form')
        } else {
          setPromptForCreateAccountEmail( () => data.omniauth_email )
          setMode(() => 'log_in_required')
        }
        window.drawingUrl = `/pricing?subscription_type=${subscriptionType()}`
      }
    })
  }
  useEffect(() => {
    if(subscription_type != undefined && subscription_type != '' && subscriptionType() == subscription_type) {
      handleClick()
    }
  }, [])

  const [showPopUp, setShowPopUp] = useState(false);
  const [mode, setMode] = useState('loading');
  const popupRef = useRef();

  const [subscription, setSubscription] = useState(undefined)
  const [currencyName, setCurrencyName] = useState('')
  const [promptForCreateAccountEmail, setPromptForCreateAccountEmail] = useState('')

  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
    };

    let content = (
      <>
      {subscription == undefined && <>{loadingIcon()}</> }
      {subscription != undefined && <div className="testimonials-block preview-promo-block">
        <div className="testimonials-desc">
           {mode == 'payment_form' && <ChangeCurrency currencyName={currencyName} />}
           {mode == 'payment_form' && <PaymentForm setMode={setMode} subscription={subscription} setSubscription={setSubscription} setCurrencyName={setCurrencyName}/> }
           {mode == 'payment_success' && <PaymentSuccess /> }
           {mode == 'log_in_required' && <LogInRequired mode={mode} setMode={setMode} promptForCreateAccountEmail={promptForCreateAccountEmail} callback={fetchSubscription} autoOpen={true} /> }
        </div>
      </div>}
    </>)

    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      notWide={true}
    />;
  }
  return (
    <span>
      <center>
        <a className="btn btn-price simple-btn" onClick={handleClick} > { cta != undefined ? cta : t('sign_up.get_started') } </a>
      </center>
      {showPopUp && popupContent()}
    </span>
  )
}

export default BuySubscriptionButton;