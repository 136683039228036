import React, { useState, useEffect, useRef }  from 'react';
import Constants from 'components/show_drawing/Constants.jsx';
import { GetRequest } from 'components/common/index.js';
import { OptionLenghtWithUnit, OptionBoolean, OptionIntegerWithOptions, OptionIntegerWithRange, OptionAlignment, AddWindowsPopup, OptionPresets, InternalDimensionsChooser, DimensionTextsIncludedChooser } from 'components/show_drawing/redraw_form/index.js';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'

const DrawingOptions = ({ id, drawing, unit, register, errors, optionsUserChanged, addToOptionsUserChanged, removeFromOptionsUserChanged, debug, setMaxHeight, setRedrawRequested, customerAddOnsEnabled, GetRequestPath, currentDrawingOptions, addAddOnToDieline, removeAddOnFromDieline, addOnsAsQueryStr, advancedOptionsVisible, setAdvancedOptionsVisible, dimensionTextschecked, setDimensionTextschecked}) => {
  const key = (option) => { return "" + id + option.name + unit }
  const [options, setOptions] = useState([])
  const [advancedOptions, setAdvancedOptions] = useState([])
  const [addWindowsVisible, setAddWindowsVisible] = useState(false);

  const fetchOptions = () => {
    // https://diecuttemplates.com.dev/drawing-options/0/en/72606/in
    GetRequest({
      url: `/drawing-options/0/${window.locale}/${id}/${unit}`,
      callback: (data) => {
        setOptions( () => data.values )
        fetchAdvancedOptions();
      }
    })
  }

  const fetchAdvancedOptions = () => {
    // https://diecuttemplates.com.dev/drawing-options/1/en/72606/in
    GetRequest({
      url: `/drawing-options/1/${window.locale}/${id}/${unit}`,
      callback: (data) => {
        setAdvancedOptions( () => data.values )
      }
    })
  }

  const calculateMaxHeight = () => {
    let h = $("#optionsCol").height();
    setMaxHeight( () => `${h}px` );
  }

  const DispatchOptionsUpdated = () => { emitEvent(Constants.options_updated, id)  }

  const getOption = (option) => {
    if(option.input_type == 'length' && option.possible_values.length == 0) {
      return <OptionLenghtWithUnit
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        key={key(option)}
        addToOptionsUserChanged={addToOptionsUserChanged}
        optionsUserChanged={optionsUserChanged}
        setRedrawRequested={setRedrawRequested}
      />
    }

    if(option.input_type == 'integer' && option.possible_values.length == 0) {
      return <OptionIntegerWithRange
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
        setRedrawRequested={setRedrawRequested}
      />
    }

    if(option.input_type == 'integer' && option.possible_values.length > 0) {
      throw new Error("[OptionIntegerWithOptions] is not implemented yet. Do we need this?")
      return <OptionIntegerWithOptions id={id} option={option} unit={unit} key={key(option) } addToOptionsUserChanged={addToOptionsUserChanged}  />
    }

    if(option.input_type == 'angle' && option.possible_values.length > 0) {
      return <OptionIntegerWithRange
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
        setRedrawRequested={setRedrawRequested}
      />
    }

    if(option.input_type == 'boolean') {
      return <OptionBoolean
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        setRedrawRequested={setRedrawRequested}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
      />
    }

    if(option.input_type == 'alignment') {
      return <OptionAlignment
        id={id}
        option={option}
        unit={unit}
        setRedrawRequested={setRedrawRequested}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
      />
    }

    throw new Error("[DrawingOptions] getOption returning null")
  }

  const internalDimensions = () => {
    if(!drawing.show_internal_dimensions) { return }
    return <InternalDimensionsChooser id={id} unit={unit} drawing={drawing} setRedrawRequested={setRedrawRequested} />
  }

  const removeAdvancedOptionsFromOptionsUserChanged = () => {
    advancedOptions.map(removeFromOptionsUserChanged);
  }

  const showAdvancedOptionsWithoutRedraw = (e) => {
    e?.preventDefault();
    calculateMaxHeight();
    setAdvancedOptionsVisible( () => true )
  }

  const showAdvancedOptions = (e) => {
    showAdvancedOptionsWithoutRedraw(e)
    setRedrawRequested( () => true)
  }

  const hideAdvancedOptions = (e) => {
    e.preventDefault();
    setAdvancedOptionsVisible( () => false )
    removeAdvancedOptionsFromOptionsUserChanged()
    setRedrawRequested( () => true)
  }

  const currentAddOnCount = () => {
    if(currentDrawingOptions == undefined) { return 0 }
    if(currentDrawingOptions["add_ons"] == undefined) { return 0 }

    return currentDrawingOptions["add_ons"].length;
  }

  const [onRecoverySetValueMsgReceived, setonRecoverySetValueMsgReceived] = useState(false);

  const onRecoverySetValueMsg = (msg) => {
    if (msg.detail.id != id) { return; }
    const rule = msg.detail.rule;
    if (rule.recovery_apply_to.advanced && !advancedOptionsVisible) {
      showAdvancedOptionsWithoutRedraw();
    }
  }

  const onRulesAndFormulaOptionsDoNotMatchMsg = (msg) => {
    if (msg.detail.id != id) { return; }

    showAdvancedOptionsWithoutRedraw();
  }

  const optionPresets = () => {
    if(drawing.option_presets == undefined || drawing.option_presets.length == 0) { return }

    return <OptionPresets id={id} drawing={drawing} unit={unit} setRedrawRequested={setRedrawRequested} />
  }

  // useEffect starts
  useEffect(DispatchOptionsUpdated, [options, advancedOptions])
  useEffect(fetchOptions, [id, unit]);

  MyEventListener([
    {
      key: Constants.drawing_recovery_set_value_required,
      callback: onRecoverySetValueMsg
    },
    {
      key: Constants.drawing_rules_and_formula_options_do_not_match,
      callback: onRulesAndFormulaOptionsDoNotMatchMsg
    }
  ])

  return (
    <>
      {debug && <>[{id}]</>}

      { optionPresets() }

      { internalDimensions() }

      {options.map(getOption) }

      <DimensionTextsIncludedChooser
        id={id}
        drawing={drawing}
        unit={unit}
        dimensionTextschecked={dimensionTextschecked}
        setDimensionTextschecked={setDimensionTextschecked}
      />

      { customerAddOnsEnabled && <div className={styles.optionLink}> <span key="2">
            <a href="#" onClick={ (e) => {  e.preventDefault(); setAddWindowsVisible( () => true) } }>
              <i className="zmdi zmdi-plus-square"></i> &nbsp;
              { currentAddOnCount() == 0 && t('drawing.show_add_ons_window') }
              { currentAddOnCount() > 0 && <> {t('drawing.show_add_ons_window_add_remove')} ({currentAddOnCount()}) </> }
            </a>
          </span>
        </div>
      }

      { advancedOptions.length > 0 && !advancedOptionsVisible && <div className={styles.optionLink}> <span key="1">
          <a href="#" onClick={showAdvancedOptions}> <i className="fa fa-gears"> </i> { t('drawing.show_advanced_options') } </a>
        </span> <br /> </div>
      }

      { advancedOptions.length > 0 && advancedOptionsVisible && <div className={styles.optionLink}> <span key="0">
          <a href="#" onClick={hideAdvancedOptions}> <i className="fa fa-gears"> </i> { t('drawing.hide_advanced_options') } </a>
        </span> <br /> </div>
      }

      { advancedOptionsVisible && advancedOptions.map(getOption) }

      {customerAddOnsEnabled && <AddWindowsPopup
        id={id}
        unit={unit}
        addWindowsVisible={addWindowsVisible}
        setAddWindowsVisible={setAddWindowsVisible}
        GetRequestPath={GetRequestPath}
        currentDrawingOptions={currentDrawingOptions}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
        addAddOnToDieline={addAddOnToDieline}
        removeAddOnFromDieline={removeAddOnFromDieline}
        addOnsAsQueryStr={addOnsAsQueryStr}
      /> }
    </>
  )
}

export default DrawingOptions;
