import React, { useState, useEffect, useRef } from 'react';
import { PostRequest, GetRequest } from 'components/common';
import { ContentPopup } from 'components/ContentPopup.jsx';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';
import { ListenCondition } from 'components/quote_builder/form_fields/index.js'

const UserUnitPrice = ({ config, setValue, value, values, windowValue, disabled, setFetchInProgress, options, item, navSection, requestPublishItemOnChange, requestGetExtraItems, navSectionColumnType }) => {
  const USER_UNIT_PRICE_CHANGED_WITH_ITEM_EVENT_KEY = 'quote_builder.user_unit_price_changed_with_item';
  const USER_UNIT_PRICE_ID_CHANGED_EVENT_KEY = '_user_unit_price_id_changed';

  const [allUserUnitPrices, setAllUserUnitPrices] = useState([]);
  const [userUnitPrices, setUserUnitPrices] = useState([]);
  const [configForPopUpForm, setConfigForPopUpForm] = useState({});
  const [selectedUnitPriceSummary, setSelectedUnitPriceSummary] = useState('');
  const newValueAddedID = useRef();
  const [editingMode, setEditingMode] = useState(false);
  const [listingMode, setListingMode] = useState(false);
  const [copyFromSystemMode, setCopyFromSystemMode] = useState(false);
  const [notAvailable, setNotAvailable] = useState(false);
  const VALUE_NOT_AVAILABLE = 'not-available'
  const VALUE_EMPTY = "";
  const VALUE_NEW = "new-attribute";
  const newUserPricePopupRef = useRef();
  const editUserPricePopupRef = useRef();
  const listUserPricePopupRef = useRef();
  const [notAvailableReason, setNotAvailableReason] = useState('');

  const [readOnly, setReadOnly] = useState(false);
  const [readOnlyMessage, setReadOnlyMessage] = useState('');

  const filteredData = (data, filter_eval) => {
    if(config.filter != undefined && filter_eval == undefined) {
      data = data.filter((user_unit_price) => { return eval(config.filter); } )
    }

    if(config.filters != undefined && filter_eval == undefined) {
      for(let i=0; i< config.filters.length; i++) {
        data = data.filter((user_unit_price) => { return eval(config.filters[i].filter); } )
      }
    }

    if(filter_eval != undefined) {
      data = data.filter((user_unit_price) => { return eval(filter_eval); } )
    }

    return data;
  }

  const fetchUserUnitPrices = (filter_eval) => {
    if(disabled) { return };
    setFetchInProgress(true);
    GetRequest({
      url: `/quote-builder/user_unit_prices/list/section/${config.key}/${window.locale}`,
      callback: (data) => {
        setAllUserUnitPrices(data);

        setFetchInProgress(false);
        data = filteredData(data, filter_eval);

        setUserUnitPrices(data);
      }
    })
  };

  useEffect(() => {
    if(disabled) { return };

    fetchUserUnitPrices();
  },[]);


  const valueTobeSetAfterFetch = useRef(null);

  useEffect(() => {
    if(!disabled && config.view_selected_summary) {
      updateSelectedUnitPriceSummary();
    }

    if(userUnitPrices.length == 0 && value != undefined && value != 'not-available') {
      valueTobeSetAfterFetch.current = value;
    }

  }, [value, userUnitPrices])

  useEffect(() => {
    if(valueTobeSetAfterFetch.current != null && userUnitPrices.length > 0) {
      setValue(config.name, (_p) => { return valueTobeSetAfterFetch.current })
      setValue('updated_at', () => { return Date.now() } );

    }
  }, [userUnitPrices] )

  useEffect(() => {
    if(config.refetch_list_on_window_value_change) {
      fetchUserUnitPrices();
    }

  }, [windowValue]);

  const setValueToFirstAvailableOption = () => {
    setValue(config.name, userUnitPrices.length > 0 ? (p) => { return userUnitPrices[0].id } : (p) => { return VALUE_NOT_AVAILABLE });
    setValue('updated_at', () => { return Date.now() } );
  }

  const setValueToNotAvailable = () => {
    setValue(config.name,  (p) => { return VALUE_NOT_AVAILABLE });
    setValue('updated_at', () => { return Date.now() } );
  }

  useEffect(() => {
    if(disabled) { return };

    if(newValueAddedID.current != undefined) {
      setValue(config.name,  (p) => { return newValueAddedID.current } );
      setValue('updated_at', ( ) => { return Date.now() } );
      return;
    }

    if(checkForValueInUserPricesRequired.current && value != VALUE_NOT_AVAILABLE && value != undefined && value != null) {
      let valueFound = false;
      for(let i=0; i< userUnitPrices.length; i++) {
        if(userUnitPrices[i].id == value) {
          valueFound = true;
        }
      }

      if(!valueFound && config.not_available_enabled) {
        setValueToNotAvailable();
        checkForValueInUserPricesRequired.current = false;
        return;
      }

      if(!valueFound && !config.not_available_enabled) {
        setValueToFirstAvailableOption();
        checkForValueInUserPricesRequired.current = false;
        return;
      }
    }

    if(config.select_first_item_on_load) {
      setValueToFirstAvailableOption();
      return;
    }

  },[userUnitPrices]);

  const onNewUserPricePopupClose = () => {
    callRequestPublishItemOnChange();
    fetchUserUnitPrices();
    fetchSystemUnitPrices();
    publishUserUnitPriceListReFetchRequired();
  }

  const publishUserUnitPriceListReFetchRequired = () => {
    document.dispatchEvent(new CustomEvent(USER_UNIT_PRICE_ID_CHANGED_EVENT_KEY, { detail: { id: value, key: config.key, navSectionColumnType: navSectionColumnType } } ));
  }

  useEffect(() => {
    let listener = (e) => {
      if(e.detail.key == config.key && e.detail.navSectionColumnType != navSectionColumnType && e.detail.id != 'not-available') {
        fetchUserUnitPrices();
      }
    }

    document.addEventListener(USER_UNIT_PRICE_ID_CHANGED_EVENT_KEY,  listener);
    return () => { document.removeEventListener(USER_UNIT_PRICE_ID_CHANGED_EVENT_KEY, listener);}
  }, []);

  const newUserPricePopup = () => {
    if(Object.keys(configForPopUpForm).length == 0) return;
    let currentValue = value;
    let callback = (id) => {
      newValueAddedID.current = id;

      if(newValueAddedID.current != undefined) {
        onNewUserPricePopupClose();
      } else {
        setValueToNotAvailable();
      }

      newUserPricePopupRef.current.style.display = "none";
      checkForValueInUserPricesRequired.current = true;
      setEditingMode(false);
      setListingMode(false);
      setCopyFromSystemMode(false);
    }
    let valuesContext = {};

    if(config.create_context != undefined) {
      for (const [contextKey, contextValue] of Object.entries(config.create_context)) {
        valuesContext[contextKey] = eval(contextValue)
      }
    }
    // what is this context for? we can pass values that can be used by config.create_read_only_values or anything like that
    // QuoteBuilderForm.jsx looks like it does not use context if you read the codebase but its called from sections.yml
    let content = <QuoteBuilderForm config={configForPopUpForm} showingState="new" afterSave={callback} afterCancel={callback} readOnlyValues={config.create_read_only_values} context={valuesContext} options={options} />;
    let response = <ContentPopup myRef={newUserPricePopupRef} autoOpen={true} content={content} cancelButton={false} beforeClose={callback} />;

    return response;
  }

  const checkForValueInUserPricesRequired = useRef(false);

  const editPricePopup = () => {
    if(Object.keys(configForPopUpForm).length == 0) return;

    let callback = () => {
      setEditingMode(false);
      setListingMode(false);
      setCopyFromSystemMode(false);
      onNewUserPricePopupClose();
      setValue('updated_at', () => { return Date.now() } );
      callRequestPublishItemOnChange();
      editUserPricePopupRef.current.style.display = "none";
      publishUserUnitPriceListReFetchRequired();
      checkForValueInUserPricesRequired.current = true;
    }

    let content = <QuoteBuilderForm config={configForPopUpForm} showingState="edit" afterSave={callback} afterCancel={callback} editStateId={value} linkToSettings={true} />;
    let response = <ContentPopup myRef={editUserPricePopupRef} autoOpen={true} content={content} cancelButton={false} beforeClose={callback} />;

    return response;
  }

  const copyFromSystemPopup = () => {
    if(Object.keys(configForPopUpForm).length == 0) return;

    let callback = (id) => {
      setEditingMode(false);
      setListingMode(false);
      setCopyFromSystemMode(false);
      newValueAddedID.current = id;
      if(newValueAddedID.current != undefined) {
        onNewUserPricePopupClose();
      } else {
        setValueToNotAvailable();
      }

      newUserPricePopupRef.current.style.display = "none";
      checkForValueInUserPricesRequired.current = true;
    }

    let content = <QuoteBuilderForm config={configForPopUpForm} showingState="copy_from_system" afterSave={callback} afterCancel={callback} editStateId={copyFromSystemId} linkToSettings={true} />;
    let response = <ContentPopup myRef={editUserPricePopupRef} autoOpen={true} content={content} cancelButton={false} beforeClose={callback} />;

    return response;
  }

  const listingModePopup = () => {
    if(Object.keys(configForPopUpForm).length == 0) return;

    let callback = () => {
      setEditingMode(false);
      setListingMode(false);
      setCopyFromSystemMode(false);
      onNewUserPricePopupClose();
      callRequestPublishItemOnChange();
      listUserPricePopupRef.current.style.display = "none";
      checkForValueInUserPricesRequired.current = true;
    }

    let content = <QuoteBuilderForm config={configForPopUpForm} showingState="list" afterSave={callback} afterCancel={callback} editStateId={value} hideDeleteFromList={false} />;
    let response = <ContentPopup myRef={listUserPricePopupRef} autoOpen={true} content={content} cancelButton={false} beforeClose={callback} />;

    return response;
  };

  const listAllItems = (e) => {
    e.preventDefault();
    setListingMode(true);
  }

  const handleEdit = (e) => {
    setEditingMode(true);
    e.preventDefault();
  }

  useEffect(() => {
    if(disabled) { return };
    if(config.create_enabled || config.edit_selected_item_enabled) {
      setFetchInProgress(true);
      GetRequest({
        url: `/quote-builder/config/settings/user_unit_prices/${config.key}`,
        useCache: true,
        callback: (data) => {
          setFetchInProgress(false);
          setConfigForPopUpForm(data);
        }
      })
    }
  }, []);

  const updateSelectedUnitPriceSummary = () => {
    setSelectedUnitPriceSummary('');
    for(let i = 0; i < userUnitPrices.length; i++) {
      if(parseInt(value) == parseInt(userUnitPrices[i].id)) {
        setSelectedUnitPriceSummary(userUnitPrices[i].summary);
      }
    }
  }

  const setValueWrapped = (e) => {
    let price_id = e.target.value;
    let isSystem = false;

    for(let i=0; i< systemUnitPrices.length; i++) {
      if(parseInt(price_id) == parseInt(systemUnitPrices[i].id)) {
        isSystem = true;
      }
    }
    if(isSystem) {
      setCopyFromSystemId(price_id);
      setCopyFromSystemMode(true);
    }else {
      setValue(config.name, price_id);
      setValue('updated_at', Date.now());
    }
  }

  const filteredBy = () => {
    if(config.filter_wording_eval != undefined) {
      return t( 'quote_builder.' + eval(config.filter_wording_eval)) + ' ' + t('quote_builder.unit_prices')
    }

    if(config.filter_wording != undefined) {
      return t('quote_builder.' + config.filter_wording)
    }
  }

  const filteredNumbers = () => {
    return t('quote_builder.showing_filtered_numbers').replace('%{showingNumber}', userUnitPrices.length).replace('%{all}', allUserUnitPrices.length)
  }

  const callRequestPublishItemOnChange = () => {
    if(config.publish_item_on_change && config.publish_item_if_eval == undefined) {
      requestPublishItemOnChange({ publish_item_key: config.publish_item_key });
    }

    if(config.publish_item_on_change && config.publish_item_if_eval != undefined && eval(config.publish_item_if_eval) ) {
      requestPublishItemOnChange({ publish_item_key: config.publish_item_key });
    }
  }

  useEffect(() => {
    callRequestPublishItemOnChange();

    if(config.get_extra_items_on_change_if == undefined && config.get_extra_items_on_change) {
      requestGetExtraItems( { key: config.key, value: value } );
    }

    if(config.get_extra_items_on_change_if != undefined && eval(config.get_extra_items_on_change_if)) {
      requestGetExtraItems( { key: config.key, value: value } );
    }

    if(config.not_available_if != undefined && eval(config.not_available_if)) {
      setNotAvailableReason(t(config.not_available_if_reason));
    } else {
      setNotAvailableReason('');
    }

  }, [value])


  if(config.listen_conditions != undefined) {
    for(let i=0; i < config.listen_conditions.length; i++) {

      useEffect(() => {
        let listener = ListenCondition.listener({
          config: config,
          condition: config.listen_conditions[i],
          item: item,
          navSection: navSection,
          setValue: setValue,
          GetRequest: GetRequest,
          setReadOnly: setReadOnly,
          setReadOnlyMessage: setReadOnlyMessage,
          setNotAvailable: setNotAvailable,
          setNotAvailableReason: setNotAvailableReason,
          options: options,
          navSectionColumnType: navSectionColumnType,
          fetchUserUnitPrices: fetchUserUnitPrices,
          fetchSystemUnitPrices: fetchSystemUnitPrices,
        });

        document.addEventListener(config.listen_conditions[i].listen, listener);
        return () => { document.removeEventListener(config.listen_conditions[i].listen, listener);}

      }, []);
    }
  }

  //create from system start
  const [systemUnitPrices, setSystemUnitPrices] = useState([]);
  const [copyFromSystemId, setCopyFromSystemId] = useState(undefined);

  const default_system_list_path = '/quote-builder/system_user_unit_prices/list/settings_list/:key/:lng';
  const [createBtnEnabled, setCreateBtnEnabled] = useState('disabled');
  const [createFromSystemEnabled, setCreateFromSystemEnabled] = useState(false);

  const fetchSystemUnitPrices = (filter_eval) => {
    GetRequest({
      url: default_system_list_path.replace(':key', config.key).replace(':lng', window.locale),
      callback: (data) => {
        data = filteredData(data, filter_eval);
        setSystemUnitPrices(data);
        setCreateFromSystemEnabled(() => { return data.length > 0});
        setCreateBtnEnabled(() => { return '' });
      }
    })
  }

  useEffect(fetchSystemUnitPrices, []);
 //create from system end

  if(notAvailableReason != undefined && notAvailableReason != '') {
    return <tr>
      <td colSpan="2">
        <div className="well">
          <i className="fa fa-exclamation-circle"></i>  <small> {notAvailableReason} </small>
        </div>
      </td>
    </tr>;
  }

  return (
    <tr>
      <td> {config.label != undefined && t(config.label)} {config.label_translated != undefined && config.label_translated } </td>
      <td align="right">

        <select value={value}
          onChange={setValueWrapped}
          name={config.name}
          disabled={readOnly}
          className={`form-control ${(notAvailable) ? 'disabledForm' : ''}`}
        >
          {config.empty_option_enabled && <optgroup label=""> <option key={VALUE_EMPTY} value={VALUE_EMPTY}> </option> </optgroup> }
          {config.not_available_enabled && <optgroup label=""> <option key="n/a" value={VALUE_NOT_AVAILABLE}> {t('quote_builder.not_included')} </option> </optgroup> }

          {systemUnitPrices.length > 0  && (<optgroup label={t('quote_builder.optgroup_system_prices')}>

            {systemUnitPrices.map((userUnitPrice) => {
              return <option
                key={userUnitPrice.id}
                data-is-system="true"
                value={userUnitPrice.id}>
                {userUnitPrice.description_for_system}
              </option>
            })}
          </optgroup>)}

          {allUserUnitPrices.length > 0 && <optgroup label={(config.filter == undefined && config.filters == undefined) ? t('quote_builder.my_unit_prices') : filteredBy() }>
            {userUnitPrices.map((userUnitPrice) => {
              return <option
                key={userUnitPrice.id}
                data-is-system="false"
                value={userUnitPrice.id}> { userUnitPrice.name != '' && userUnitPrice.name !=  undefined && `[${userUnitPrice.name}]`}
                {userUnitPrice.description}{userUnitPrice.price_with_currency_formatted != '' && userUnitPrice.price_with_currency_formatted != undefined && `, ${userUnitPrice.price_with_currency_formatted}`}
              </option>
            })}
          </optgroup>
          }

          {allUserUnitPrices.length != userUnitPrices.length && <optgroup label={filteredNumbers()} />}

          {config.create_enabled && <optgroup label=""> <option key="new" value={VALUE_NEW} disabled={createBtnEnabled}> {t(config.create_cta)} </option> </optgroup> }
        </select>

        {config.view_selected_summary && selectedUnitPriceSummary != '' && (
          <span>
            <small>
              <div dangerouslySetInnerHTML={{__html: selectedUnitPriceSummary}}></div>
            </small>
            {config.edit_selected_item_enabled && (<> &nbsp; <small> <a href="" onClick={handleEdit}> <i className="fa fa-pencil-square-o" aria-hidden="true"></i>  {t('quote_builder.edit')} </a></small> <br /></>)}
          </span>
        )}

        {allUserUnitPrices.length > 0 && (
         <> <small> <a onClick={listAllItems} href="#"> <i className="fa fa-list" aria-hidden="true"></i> {t('quote_builder.show_all_items')} </a> </small> <br /> </>
        )}

        {readOnlyMessage != '' && <> <small className="q-read-only-message"> {readOnlyMessage} <br /> </small> </> }
        {config.footer_note != undefined && (<><br /> <small> {t(config.footer_note)} <br /> </small></>)}
        {config.create_enabled && value == VALUE_NEW && newUserPricePopup()}
        {config.edit_selected_item_enabled && editingMode && editPricePopup()}
        {config.create_enabled && copyFromSystemMode && copyFromSystemPopup()}
        {listingMode && listingModePopup()}
      </td>
    </tr>
  )
}

export default UserUnitPrice;
