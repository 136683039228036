import React, { useState, useEffect } from 'react';
import { PostRequest, GetRequest, Tooltip } from 'components/common';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';
import { ValidatorFactory } from 'components/common';

const UserUnitPriceAttributeField = ({ config, value, setValue, unit, register, errors, disabled, setFetchInProgress, options, readOnlyValues, context, requestPublishItemOnChange }) => {
  const VALUE_NEW = "new-attribute";
  const VALUE_NOT_AVAILABLE = 'not-available'
  const VALUE_EMPTY = "";

  const [attributes, setAttributes] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [newAttributeValue, setNewAttributeValue] = useState('');
  const [newAttributesAdded, setAttributesAdded] = useState(false);
  const [newAttributeFormError, setNewAttributeFormError] = useState('');

  const [readOnly, setReadOnly] = useState(false);
  const [defaultValue, setDefaultValue] = useState(config.default_value);

  const validate = (val) => {
    if(config.validators != undefined) {
      for(let i=0; i< config.validators.length; i++) {
        let validator = config.validators[i];
        if(!ValidatorFactory(validator, value)) {
          setValidationError(t(`quote_builder.validator_${validator}_message`));
          return t(`quote_builder.validator_${validator}_message`);
        }
      }
    }
    setValidationError('');
    return;
  }

  const handleOnChange = (e) => {
    setValue(config.name, e.target.value);
  }

  const materialIdByyMaterialAttribute = (material_attribute) => {
    let name_en = {
      carton_material_user_price_id: 'Carton',
      hard_cardboard_material_user_price_id: 'Hard cardboard',
      pvc_material_user_price_id: 'PVC',
      corrugated_cardboard_material_user_price_id: 'Corrugated cardboard',
    }[material_attribute];

    if(name_en == undefined || attributes.length == 0)   { return undefined }

    let attribute = Array.from(attributes).find((attribute) => attribute.name_en == name_en );
    if(attribute == undefined) { return undefined }

    return attribute.id;
  }

  useEffect(() => {
    if(attributes.length == 0) { return; }

    if (readOnlyValues != undefined) {
      readOnlyValues.map((readOnlyValue) => {
        if(readOnlyValue.name == config.name) {

          if(readOnlyValue.value_eval != undefined) {
            let _value = eval(readOnlyValue.value_eval);
            if (_value != undefined && _value != '') {
              setTimeout(() => {
                setValue(config.name, () => { return _value } );
                setReadOnly(readOnlyValue.make_read_only == undefined ? (_prev) => true :  (_prev) => readOnlyValue.make_read_only );
              }, 250)
            }
          }
        }
      })
    }

    if(context != undefined) {
      for (const [contextKey, contextValue] of Object.entries(context)) {
        if(contextKey == config.key) {
          setTimeout(() => {
            setValue(config.name, () => { return contextValue } );
          }, 250);
        }
      }
    }
  }, [attributes])

  const fetchUserUnitPriceAttributes = (callback) => {
    if(disabled) { return; }

    setFetchInProgress(true);

    GetRequest({
      url: `/quote-builder/user_unit_price_attributes/list/${config.key}`,
      callback: (data) => {
        setAttributes(data);
        if(callback != undefined) { callback(); }
        setFetchInProgress(false);
      }
    })
  }

  useEffect(fetchUserUnitPriceAttributes, []);

  useEffect( () => {
    if(value != '' && value != undefined && value !='new-attribute' ) { return; }

    let valueToSet = attributes.length > 0 ? (config.empty_option_enabled ? VALUE_EMPTY : (config.not_available_enabled ? VALUE_NOT_AVAILABLE : attributes[0].id)) : (config.not_available_enabled ? VALUE_NOT_AVAILABLE : VALUE_EMPTY)

    if(newAttributesAdded) {
      valueToSet = attributes.length > 0 ? attributes[attributes.length - 1].id : (config.not_available_enabled ? VALUE_NOT_AVAILABLE : VALUE_EMPTY)
    }

    if(defaultValue != '' && defaultValue != undefined) {
      for(let i=0; i< attributes.length; i++) {
        if (attributes[i].name_en == defaultValue) {
          setValue(config.name, attributes[i].id);
          return;
        }
      }
    }

    setValue(config.name, (prev) => { return valueToSet } );

  }, [attributes])

  const saveNewAttributeValue = () => {
    if(newAttributeValue == '') { setNewAttributeFormError('quote_builder.missing_value_error'); return; }
    setFetchInProgress(true);

    PostRequest({
      url: `/quote-builder/user_unit_price_attributes/create/${config.key}`,
      data: {
        name: newAttributeValue,
      },
      callback: (_data) => {
        setAttributesAdded(true);
        setNewAttributeFormError('');
        fetchUserUnitPriceAttributes();
        setValue(config.name, _data.new_attribute_id);
        setNewAttributeValue('');
        setFetchInProgress(false);
      }
    })
  }

  const closeNewAttributeForm = () => {
    setNewAttributeValue('');
    setValue(config.name, attributes[0]?.id);
  }

  const newAttributeForm = () => {
    return (
      <div className="form-inline newAttributeForm">
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-addon">
              {config.create_input_label != undefined && t(config.create_input_label)}
              {config.create_input_label == undefined && t('quote_builder.new_attribute_with_key').replace('%{key}', t(`quote_builder.${config.key}`).toLowerCase())}
              </div>
              <input
                type="text"
                className={newAttributeFormError != '' ? 'input-error form-control' : 'form-control'}
                size="15"
                value={newAttributeValue}
                onChange={(e) => {setNewAttributeValue(e.target.value)}}
                />
            </div>
          </div>

          <button type="button" className="btn simple-btn" onClick={saveNewAttributeValue} > {t('quote_builder.save')} </button> &nbsp;
          <button className="btn" type="button" onClick={closeNewAttributeForm}> {t('quote_builder.cancel')} </button>

          {newAttributeFormError != '' && <center> <small className="error"> {t(newAttributeFormError)} </small> </center> }
      </div>
    )
  }

  const newAttributeSubForm = () => {
    const afterSave = () => {
      setAttributesAdded(true);
      fetchUserUnitPriceAttributes();
    }
    return (<div>
      <QuoteBuilderForm config={config.create_sub_form} afterSave={afterSave} afterCancel={afterSave} />
    </div>)
  }

  const defaultLabel = () => {
    return t(`quote_builder.${config.key}`);
  }

  const defaultCreateCta = () => {
    return t(`quote_builder.new_user_unit_price`).replace("%s", t(`quote_builder.${config.key}`))
  }

  const callRequestPublishItemOnChange = () => {
    if(config.publish_item_on_change && config.publish_item_if_eval == undefined) {
      requestPublishItemOnChange({ publish_item_key: config.publish_item_key });
    }

    if(config.publish_item_on_change && config.publish_item_if_eval != undefined && eval(config.publish_item_if_eval) ) {
      requestPublishItemOnChange({ publish_item_key: config.publish_item_key });
    }
  }

  useEffect(() => {
    callRequestPublishItemOnChange();
  }, [value])

  return (
    <tr>
      <td>
        { config.label != undefined && t(config.label) }
        { config.label == undefined && defaultLabel() }
        { config.tooltip_text != undefined && <Tooltip title={t(config.tooltip_text)} /> }
      </td>
      <td align="right">
        <select
          name={config.name}
          value={value}
          disabled={readOnly}
          className={`form-control general-frm-element ${validationError != '' ? 'input-error' : ''}`}
          {...register(config.name.replace('.', '_'), {
          validate: validate,
          onChange: handleOnChange
        })}
         >
        {config.empty_option_enabled && <optgroup label="" ><option key={VALUE_EMPTY} value={VALUE_EMPTY}> </option> </optgroup> }
        {config.not_available_enabled && <optgroup label="" ><option key="n/a" value={VALUE_NOT_AVAILABLE}> {t('quote_builder.not_included')} </option> </optgroup> }
        {attributes.map((attribute) => {
          return <option
            key={attribute.id}
            value={attribute.id}> { window.locale == 'en' ? attribute.name_en : attribute.name_tr }
          </option>
        })}

        {config.create_enabled && <optgroup label="" ><option key="new_attribute" value={VALUE_NEW}>
        {config.create_cta != undefined && t(config.create_cta)}
        {config.create_cta == undefined && defaultCreateCta() }
        </option> </optgroup> }

      </select>

      {validationError != '' && <small className="error"> {validationError} </small>}

      {config.create_enabled && value == VALUE_NEW && config.create_sub_form == undefined && newAttributeForm() }
      {config.create_enabled && value == VALUE_NEW && config.create_sub_form != undefined && newAttributeSubForm() }
      </td>
    </tr>
  )
}

export default UserUnitPriceAttributeField;
