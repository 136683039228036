import React, { useState, useEffect, useContext } from 'react';
import LayoutContext from 'components/contexts/LayoutContext.jsx';
import RedrawDataToPost from  'components/layout/RedrawDataToPost.jsx';

const LayoutSvg = ( { klass, id, parts, partNames, redrawUrl, uuid, activeRedraws, setActiveRedraws, setRedrawRequested, redrawRequested, recoveryApplyMethods, redrawInProgress, setRedrawInProgress, redrawForm} ) => {
  const LAYOUT_REDRAW_FINISHED = 'layout_redraw_finished';

  const myLayoutContext = useContext(LayoutContext);

  const getLoading = () => {
    switch(theme) {
      case 'black_bg':
        return '<center> <br /> <br /> <br /> <img src="/double-ring-white.gif" width="30px"/> </center>'; break;
      case 'white_bg':
        return '<center> <br /> <br /> <br /> <img src="/bx_loader.gif" width="30px"/> </center>'; break;
      case 'gray_bg':
        return '<center> <br /> <br /> <br /> <img src="/bx_loader.gif" width="30px"/> </center>'; break;
    }
  }

  const [svg, setSvgString] = useState(getLoading());
  const [theme, setTheme] = useState(myLayoutContext.theme);
  const [outputYLength, setOutputYLength] = useState(null);
  const [outputXLength, setOutputXLength] = useState(null);
  const [lengthsByColorCodeFormatted, setLengthsByColorCodeFormatted] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [layoutOverflows, setLayoutOverflows] = useState(false);
  const [layoutUsesDifferentDrawing, setLayoutUsesDifferentDrawing] = useState(false);
  const [onlyForPlotter, setOnlyForPlotter] = useState(false);
  const [showQuoteBuilderPromo, setShowQuoteBuilderPromo] = useState(false);

  const [brokenRule, setBrokenRule] = useState(null);
  const [numX, setNumX] = useState(null);
  const [numY, setNumY] = useState(null);
  const [numTotal, setNumTotal] = useState(null);

  const onRedrawStarted = () => setActiveRedraws(list => [...list, uuid]);

  useEffect( () => {
    let listener = (e) => {
      if(e.detail == uuid) {
        setRedrawRequested(() => true);
      }
    };
    document.addEventListener('REDRAW_REQUESTED', listener);
    return () => { document.removeEventListener('REDRAW_REQUESTED', listener);}
  }, [uuid])

  useEffect( () => {
    let listener = (e) => {
      setRedrawRequested(() => true);
    };
    document.addEventListener('layouts.download_buttons_ready', listener);
    return () => { document.removeEventListener('layouts.download_buttons_ready', listener);}
  }, [])

  const onRedrawFinished = (data) => {
    if(redrawForm != undefined && redrawForm.current != undefined) {
      redrawForm.current.className = null;
    }

    setSvgString(_prev => data.svg);
    setErrorMessage( (_prev) => { return null});
    setBrokenRule( (_prev) => { return null} );

    setOutputXLength(data.output_dimensions.output_x_length)
    setOutputYLength(data.output_dimensions.output_y_length)

    setLengthsByColorCodeFormatted(() => data.lengths_by_color_code_formatted);

    setActiveRedraws(prev => prev.filter(item => item != uuid));

    var layoutOptions                        = objectifyForm($(`#form_${uuid}`));
    let layoutOptionsAdminPanel = objectifyForm($("#admin_panel_form"));
    for (let key in layoutOptionsAdminPanel) { layoutOptions[key] = layoutOptionsAdminPanel[key];}

    window.confirmedOptions[uuid]            = Object.assign({ layout: layoutOptions, authenticity_token: layoutOptions.authenticity_token }, window.dielineOptions);
    window.confirmedOptionsUserEntered[uuid] = Object.assign({ layout: layoutOptions, authenticity_token: layoutOptions.authenticity_token }, window.optionsUserEntered);
    window.confirmedOptionsUserEntered[uuid].layout.num_x = data.num_x;
    window.confirmedOptionsUserEntered[uuid].layout.num_y = data.num_y;
    window.confirmedOptionsUserEntered[uuid].layout.num_total = data.num_total;
    window.confirmedOptionsUserEntered[uuid].layout.num_of_drawings_in_set = data.num_of_drawings_in_set;
    window.confirmedOptionsUserEntered[uuid].layout.lengths_by_color_code = data.lengths_by_color_code;
    window.confirmedOptionsUserEntered[uuid].layout.output_dimensions_in_mm = data.output_dimensions_in_mm;
    window.svg_initial_stroke = null;
    setNumX(data.num_x);
    setNumY(data.num_y);
    setNumTotal(data.num_total);
    setLayoutUsesDifferentDrawing(data.uses_different_drawing);
    setOnlyForPlotter(data.only_for_plotter);
    setLayoutOverflows(data.layout_overflows);
    setShowQuoteBuilderPromo(data.show_quote_builder_promo);

    if(data.show_quote_builder_promo) { trackEvent('js_quote_builder_promo_seen', { page: 'nesting' } ) }
    if(data.layout_overflows) {
      setTimeout( function() {
        $('html, body').animate({
          scrollTop: $("#error_svg_" + uuid).offset().top - 120
        }, {
          complete : function() {
            $("#redraw-error-message-cont").addClass("shaken");
            setTimeout(function() { $("#redraw-error-message-cont").removeClass("shaken"); }, 200);
          }
        }, 500);
      }, 250);
    };

    if(isLocalStorageSupported()) {
      $(`#form_${uuid} .input_use_local_storage`).each(function() {
        var attrName = $(this).attr("name");
        var attrKey = getAttrKeyForNesting(attrName, uuid);
        localStorage.setItem(attrKey, $(this).val() );
      });

      let keyForLocalStorage = "nesting-items";
      let itemsInLocalStorage = new Set((localStorage.getItem(keyForLocalStorage) || "").split(","));
      itemsInLocalStorage.add(uuid);
      localStorage.setItem(keyForLocalStorage, Array.from(itemsInLocalStorage).join(","));

      if(itemsInLocalStorage.size > 50) {
        let tempArray = Array.from(itemsInLocalStorage)
        let uuidtoDelete = tempArray.shift();
        for (var key in localStorage){
          if(key.startsWith("nesting-" + uuidtoDelete + '-')) {
            localStorage.removeItem(key);
          }
        }
        localStorage.setItem(keyForLocalStorage, tempArray.join(","));
      }
    }

    document.dispatchEvent(new CustomEvent(LAYOUT_REDRAW_FINISHED, { "detail": uuid }) );
    handleSvgShow();
    setRedrawRequested(() => false);
    setRedrawInProgress(() => false);
  }

  const onValidationError = (data) => {
    if(redrawForm != undefined && redrawForm.current != undefined) {
      redrawForm.current.className = null;
    }

    setRedrawInProgress(() => false);
    setRedrawRequested(() => false);
    setActiveRedraws(prev => prev.filter(item => item != uuid));
    if(data.rules.length > 0 ) { setBrokenRule(data.rules[0])};
    if(svg == getLoading()) { setSvgString((prev) => '') }
    setOutputXLength(null);
    setOutputYLength(null);
    setLayoutUsesDifferentDrawing(() => false);

    var completeCalled = false;
      setTimeout( function() {
        $('html, body').animate({
          scrollTop: $("#error_svg_" + uuid).offset().top - 120
        }, {
          complete : function(){
            if(!completeCalled){
              disableDownloadButtons();
              completeCalled = true;
              $("#redraw-error-message-cont").addClass("shaken");
              setTimeout(function() { $("#redraw-error-message-cont").removeClass("shaken"); }, 200);
            }
          }
        }, 500);
      }, 250);
  }

  const recoveryApply = (name, value) => {
    recoveryApplyMethods.current[name]( parseFloat(value).toFixed( window.currentUnit == 'mm' ? 2 : 4) );
    $(`#RedrawLayoutButton_${uuid}`).trigger('click');
  }

  const redraw = () => {
    if(redrawInProgress) { return };
    if(!redrawRequested) { return };

    setRedrawInProgress(() => true);
    onRedrawStarted();
    if(redrawForm != undefined && redrawForm.current != undefined) {
      redrawForm.current.className = "disabledForm";
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(RedrawDataToPost( { uuid: uuid }))
    };

    fetch(redrawUrl, requestOptions)
      .then(response => response.json())
      .then((data) => {
        switch(data.result) {
          case 'OK':
            onRedrawFinished(data);
            break;
          case 'validation_error':
            onValidationError(data);
            break;
          default:
            console.log('not good');
          }
      }
    )
  }

  const changeTheme = (e) => {
    let theme = e.target.value;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        authenticity_token: $('meta[name=csrf-token]').attr('content'),
        theme: theme
      })
    };

    fetch('/update-redraw-theme', requestOptions)
      .then(response => response.json())
      .then((data) => {
        setTheme(theme);
        setRedrawRequested(() => true);
      })
  }

  const handleSvgShow = () => {
    if($(`#svg_${uuid}`).length == 0) { return; }
    $(`#svg_${uuid}`).height($(`#layout_svg_${uuid}`).height() - $(`#error_svg_${uuid}`).height());

    performPanZoomInstance(`#svg_${uuid}`);

    $(".ctl_abc_line").off('focus');
    $(".ctl_abc_line").focus(function(e) {
      $(".abc").fadeTo( "fast" , 0);
      $( ".abc-" + $(this).data("abc-line")).fadeTo( "slow" , 2);
      setTimeout(function(){
        $(".abc").fadeTo( "slow" , 0);
      }, 6000);
    });

    window.dimension_texts_included ? $(".dmt").show() : $(".dmt").hide()
  }
  useEffect(handleSvgShow, [svg, brokenRule, layoutOverflows]);

  useEffect(redraw, [ redrawRequested ])

  const toggleTemplateActivations = (e) => {
    e.preventDefault();
    let cur = getCookie('template_activations');
    if(cur == 'false') {
      setCookie('template_activations', 'true', 365);
    } else {
      setCookie('template_activations', 'false', 365);
    }
    window.location.reload(true);
  }

  const hideQuoteBuilderPromo = () => {
    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({key: 'quote_builder_promo_on_nesting_page', value: 'false', authenticity_token: $('meta[name=csrf-token]').attr('content')})
    };

    fetch('/my-user-params', requestOptions)
      .then(response => response.json())
      .then((data) => {
       setShowQuoteBuilderPromo(() => {return false })
      }
    )
  }

  const triggerBuildQuote = () => {
    showQuoteBuilderBuildOrListQuotes();
  }

  const lengthsByColorCodeFormattedRows = () => {
    let res = [];

    for (const [key, value] of Object.entries(lengthsByColorCodeFormatted)) {
      res.push(
        <tr key={key}>
          <td>
            {key}
          </td>
          <td align="right">
          {value}
          </td>
        </tr>
      )
    }
    return res;
  }

  const outputsTable = () => {
    return (
     <table className="table" style={{"width": "80%"}}>
        <tbody>
          {outputXLength !=undefined  && <tr key="output_x_length">
            <td>
              <span className="arrow"> <i className="fa fa-arrows-h" aria-hidden="true"></i> </span> &nbsp;
              {t('layouts.output_x_length')}
            </td>

            <td align="right">
              {outputXLength}
            </td>
          </tr>}

          {outputYLength != undefined && <tr key="output_y_length">
            <td>
              <span className="arrow"> <i className="fa fa-arrows-v" aria-hidden="true"></i> </span>  &nbsp;
              {t('layouts.output_y_length')}
            </td>

            <td align="right">
              {outputYLength}
            </td>
          </tr>}
          </tbody>
      </table>);
  }

  const lengthsByColorCodeFormattedTable = () => {
    return (
      <table className="table" style={{"width": "80%"}}>
        <tbody>
          {lengthsByColorCodeFormattedRows()}
        </tbody>
      </table>)
  }

  return (
    <>
      <div className={`layout_svg layout_svg_${theme} ${redrawInProgress ? 'redraw_in_action' : '' }`} id={`layout_svg_${uuid}`}>

       {brokenRule != null && (
        <div className="col-lg-12 float-right error_svg" id={`error_svg_${uuid}`}>
          <div className="alert alert-danger fade in">
            <i className="fa fa-exclamation-circle"></i> <span dangerouslySetInnerHTML={{__html: brokenRule.description}}></span>
            {brokenRule.recovery_apply_to != null && (<>
              <button className="btn btn-default simple-btn btn-sm"
                onClick={() => recoveryApply(brokenRule.recovery_apply_to.name, brokenRule.recovery_apply_to.value) }>
                <span dangerouslySetInnerHTML={{__html: brokenRule.recovery_apply_to.cta}}></span>
              </button> {window.wording_or_try_again_with_correct_values}
            </>
            )}
          </div>
        </div>
        )}

       {showQuoteBuilderPromo && !layoutOverflows && !layoutUsesDifferentDrawing && !onlyForPlotter && (
        <div className="col-lg-12 float-right" id={`error_svg_${uuid}`}>
          <div className="alert alert-info fade in">
            <button type="button" class="close" onClick={hideQuoteBuilderPromo}><small>×</small></button>
              <p>
               <strong> <span dangerouslySetInnerHTML={{__html: t('quote_builder.promo_on_nesting')}}></span> </strong>
                  <button onClick={triggerBuildQuote} className="btn btn-default simple-btn"> {t('quote_builder.promo_on_nesting_cta')}
                </button>
              </p>
          </div>
        </div>
        )}


       {layoutOverflows && (
        <div className="col-lg-12 float-right error_svg" id={`error_svg_${uuid}`}>
          <div className="alert alert-warning fade in">
            <i className="fa fa-exclamation-circle"></i> <strong> &nbsp; {window.wording_layout_does_not_fit} </strong>
          </div>
        </div>
        )}

       {layoutUsesDifferentDrawing && (
        <div className="col-lg-12 float-right" id={`error_svg_${uuid}`}>
          <div className="alert alert-info fade in">
            <i className="fa fa-info-circle"></i>&nbsp; <strong> {t('layouts.nesting_uses_different_drawing')} </strong>
          </div>
        </div>
        )}

       {onlyForPlotter && (
        <div className="col-lg-12 float-right" id={`error_svg_${uuid}`}>
          <div className="alert alert-info fade in">
            <i className="fa fa-info-circle"></i>&nbsp; <strong> {t('layouts.nesting_is_only_for_plotter')} </strong>
          </div>
        </div>
        )}

        <div className={`${(redrawInProgress) ? '' : 'redraw_theme_' + theme }`} dangerouslySetInnerHTML={{__html: svg}}></div>
      </div>

      <br />

      <div className="row">
        <div className="col-lg-12 layout-output">
          <div style={{"float": "left"}}>
            <select id="theme_white_bg_btn" value={theme} onChange={changeTheme}>
              <option value="black_bg"> {window.drawing_black_background} </option>
              <option value="white_bg"> {window.drawing_white_background} </option>
              <option value="gray_bg">  {window.drawing_gray_background}  </option>
            </select>
          </div>

          <div className="right" style={{'marginRight': '-20px'}}>
            {partNames.length > 0 && <><strong> {partNames.join(', ')} </strong>  &nbsp; &nbsp; &nbsp; </> }
            { numX && (
              <span className="info-quote">
                { (numTotal == undefined || numX * numY == numTotal) && <> ({numX} x {numY}) </> }
                { numTotal != undefined && numX * numY != numTotal && <>  &nbsp; ({numTotal}) </> }
                &nbsp; &nbsp;
              </span>)}
          </div>

          <div style={{'clear': 'both'}}> </div>

          <div className="right">
            {Object.keys(lengthsByColorCodeFormatted).length > 0 && (
              <table style={{"width": "550px", 'marginRight': '-20px'}}>
                <tbody>
                <tr>
                  <td valign="top" align="left"> {outputsTable()} </td>
                  <td valign="top" align="right"> {lengthsByColorCodeFormattedTable()} </td>
                </tr>
                </tbody>
              </table>)}

           {window.allowSingleDownloads && window.singleDownloadPath != undefined && (
            <div className="right">
              <hr />
                <a href={`${window.singleDownloadPath}/${klass}/${id}`} target="_blank"> [admin]  ac  </a> <br />
                <a href="#" onClick={toggleTemplateActivations}> [admin] Sablon Aktivaston ac/kapa</a>
            </div>)}

          </div>
      </div>
  </div>


    </>
  )
}

export default LayoutSvg;